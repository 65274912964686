
import { apiOptions, apiRequest } from "../utils";
import { API_V1 } from "../constants";
import { BASE_URL } from "../constants";
import { log } from 'deck.gl';
export const User_lists = {
    userListDetails: async (Tokens, params) => {
        const userListURL = `${BASE_URL}${API_V1}/admin/users`;
        const options = await apiOptions({
            url: userListURL,
            method: "get",
            Tokens,
            params
        });
        return apiRequest(options);
    },
    userListPreview: async (Tokens, id,) => {

        const userListURL = `${BASE_URL}${API_V1}/admin/user/${id}`;
        const options = await apiOptions({
            url: userListURL,
            method: "get",
            Tokens,
        });
        return apiRequest(options);

    },



    getUserDetails: async (id, Token) => {
        const itemURL = `${BASE_URL}/v1/admin/order/${id}`;
        const options = await apiOptions({
            url: itemURL,
            method: "get",
            Token
        });
        return apiRequest(options);
    },
    getUserservicecategory: async (Token) => {
        const itemURL = `${BASE_URL}v1/user/servicecategory`;
        const options = await apiOptions({
            url: itemURL,
            method: 'get',
            Token

        });
        return apiRequest(options);
    }
}
